import { Grid } from 'react-loader-spinner';
import './LoadingAnimation.scss';

function LoadingAnimation() {
    return (
        <div className="loading-animation">
            <Grid
                visible={true}
                height="80"
                width="80"
                color="#FFFFFF"
                ariaLabel="grid-loading"
                radius="12.5"
                wrapperStyle={{}}
                wrapperClass="grid-wrapper"
            />
        </div>
    );
}

export default LoadingAnimation;
