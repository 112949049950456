import usePageTitle from '../hooks/usePageTitle';
import './PrivacyTerms.scss';

function Terms() {
    usePageTitle('Searchle - Terms of Service');

    const logoSrc = `${process.env.PUBLIC_URL}/Searchle-logo2.svg`;

    return (
        <div className="PrivacyTerms">
            <main className="PrivacyTerms-main">
                <center>
                    <a href="/">
                        <img src={logoSrc} className="Searchle-logo" alt="Searchle logo" width="180px" height="29px" />
                    </a>
                    <br />
                    <h2>Terms of Service</h2>
                </center>
                <i>Effective Date: July 25, 2024</i>
                <br />
                <br />
                Welcome to our website. By accessing and using our website, you agree to comply with and be bound by the
                following terms and conditions. If you do not agree with any part of these terms, you are prohibited
                from using our website.
                <br />
                <h4>Use of the Website</h4>
                <ul>
                    <li>
                        Content: The content provided on this website is for general informational purposes only. We
                        reserve the right to make changes to the content at any time without notice.
                    </li>
                    <li>
                        Gameplay: The game on this website is provided “as is” without any warranties, express or
                        implied. We do not guarantee that the game will be free from errors or interruptions.
                    </li>
                </ul>
                <h4>Intellectual Property</h4>
                All content on this website, including the game, text, graphics, logos, and images, is our property or
                the property of our content suppliers and is protected by copyright and other intellectual property
                laws.
                <h4>Limitation of Liability</h4>
                We will not be liable for any damages arising from the use or inability to use this website, including
                but not limited to direct, indirect, incidental, punitive, and consequential damages.
                <h4>Third-Party Links</h4>
                Our website may contain links to third-party websites. These links are provided for your convenience and
                do not signify our endorsement of such websites. We have no control over the content of these sites and
                are not responsible for their content or your use of them.
                <h4>Governing Law</h4>
                These terms and conditions are governed by and construed in accordance with the laws of [Your
                Jurisdiction], and you irrevocably submit to the exclusive jurisdiction of the courts in that state or
                location.
                <h4>Changes to These Terms</h4>
                We reserve the right to modify these terms and conditions at any time. Your continued use of the website
                following any changes signifies your acceptance of the new terms.
                <h4>Contact Us</h4>
                If you have any questions about these Terms of Service, please contact us at{' '}
                <a href="mailto:info@searchlegame.com">info@searchlegame.com</a>.
            </main>
        </div>
    );
}

export default Terms;
