import { useRef, useEffect } from 'react';

function usePageTitle(title, prevailOnUnmount = false) {
    const defaultTitle = useRef(document.title);

    function updateMetaTag(name, content) {
        let element = document.querySelector(`meta[name="${name}"]`);
        if (!element) {
            element = document.createElement('meta');
            element.setAttribute('name', name);
            document.head.appendChild(element);
        }
        element.setAttribute('content', content);
    }
    function updateMetaTagByProperty(property, content) {
        let element = document.querySelector(`meta[property="${property}"]`);
        if (!element) {
            element = document.createElement('meta');
            element.setAttribute('property', property);
            document.head.appendChild(element);
        }
        element.setAttribute('content', content);
    }

    useEffect(() => {
        document.title = title;
        updateMetaTag('og:title', defaultTitle.current);
        updateMetaTagByProperty('og:site_name', defaultTitle.current);
    }, [title]);

    useEffect(
        () => () => {
            if (!prevailOnUnmount) {
                document.title = defaultTitle.current;
                updateMetaTag('og:title', defaultTitle.current);
                updateMetaTagByProperty('og:site_name', defaultTitle.current);
            }
        },
        [prevailOnUnmount],
    );
}

export default usePageTitle;
