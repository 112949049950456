import Button from '../../Button';
import './ControlButtons.scss';

export default function ControlButtons({
    isStarted,
    appReady,
    isPaused,
    handleStartAsync,
    handleResumeAsync,
    handlePause,
}) {
    const StartButton = (
        <Button onClick={handleStartAsync} isPrimary={true}>
            Start
        </Button>
    );
    const PauseButton = (
        <Button onClick={handlePause} isCancel={true}>
            Pause
        </Button>
    );
    const ResumeButton = (
        <Button onClick={handleResumeAsync} isPrimary={true}>
            Resume
        </Button>
    );

    return (
        <div className="Control-Buttons">
            {!isStarted && !isPaused && StartButton}
            {isStarted && !isPaused && appReady && PauseButton}
            {isStarted && isPaused && ResumeButton}
        </div>
    );
}
