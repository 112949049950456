import usePageTitle from '../hooks/usePageTitle';
import './PrivacyTerms.scss';

function Privacy() {
    usePageTitle('Searchle - Privacy Policy');

    const logoSrc = `${process.env.PUBLIC_URL}/Searchle-logo2.svg`;

    return (
        <div className="PrivacyTerms">
            <main className="PrivacyTerms-main">
                <center>
                    <a href="/">
                        <img src={logoSrc} className="Searchle-logo" alt="Searchle logo" width="180px" height="29px" />
                    </a>
                    <br />
                    <h2>Privacy Policy</h2>
                </center>
                <i>Effective Date: July 25, 2024</i>
                <br />
                <br />
                Thank you for visiting our website. We value your privacy and want to ensure you understand how we
                handle your information. This Privacy Policy outlines the types of information we collect and how we use
                it.
                <br />
                <h4>Information We Collect</h4>
                <ul>
                    <li>
                        Google Analytics: We use Google Analytics to collect anonymized data about how visitors use our
                        website. This helps us improve our site and user experience. The information collected includes,
                        but is not limited to, your IP address, browser type, referring/exit pages, and the date and
                        time of your visits.
                    </li>
                    <li>
                        Google AdSense: We display ads through Google AdSense, which may use cookies to serve ads based
                        on your prior visits to our website or other websites.
                    </li>
                </ul>
                <h4>Use of Information</h4>
                <ul>
                    <li>
                        Analytics: The data collected by Google Analytics is used to analyze trends, administer the
                        site, track user movements around the site, and gather demographic information.
                    </li>
                    <li>
                        Advertising: Google AdSense uses cookies to serve ads based on your prior visits to our website
                        or other websites. You can opt-out of personalized advertising by visiting Google’s Ads
                        Settings.
                    </li>
                </ul>
                <h4>Cookies</h4>
                Cookies are small text files placed on your device to collect standard internet log information and
                visitor behavior information. For more information on how Google uses cookies and how you can manage
                them, please refer to Google’s Privacy Policy.
                <br />
                <h4>Data Security</h4>
                We take reasonable measures to protect the data collected through our website. However, please be aware
                that no security measures are perfect or impenetrable.
                <h4>Changes to This Privacy Policy</h4>
                We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new
                Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
                <h4>Contact Us</h4>
                If you have any questions about this Privacy Policy, please contact us at{' '}
                <a href="mailto:info@searchlegame.com">info@searchlegame.com</a>.
            </main>
        </div>
    );
}

export default Privacy;
