import usePageTitle from '../hooks/usePageTitle';
import './ErrorPage.scss';

function BadRequest() {
    usePageTitle('Searchle - 400');

    const logoSrc = `${process.env.PUBLIC_URL}/Searchle-favicon.png`;

    return (
        <div className="ErrorPage">
            <main className="ErrorPage-main">
                <img src={logoSrc} className="Searchle-logo" alt="Searchle logo" width="300px" />
                <br />
                Bad request (400)
            </main>
        </div>
    );
}

export default BadRequest;
