import { useEffect, useState } from 'react';
import SearchleGame from '../components/searchle/SearchleGame';
import usePageTitle from '../hooks/usePageTitle';
import { getDateFull } from '../sharedHelpers';
import './Searchle.scss';

function Searchle() {
    usePageTitle('Searchle! A daily word search game.');

    const logoSrc = `${process.env.PUBLIC_URL}/Searchle-logo2.svg`;
    const date = getDateFull();

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    return (
        <div className="Searchle">
            <main className="Searchle-main">
                <img src={logoSrc} className="Searchle-logo" alt="Searchle logo" width="180px" height="29px" />
                <center>{date}</center>
                <SearchleGame />
            </main>
        </div>
    );
}

export default Searchle;
