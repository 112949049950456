import React from 'react';
import './Button.scss';

const Button = ({ onClick, children, isPrimary, isAlt, isDanger, isCancel }) => {
    return (
        <button
            className={
                'button' +
                (isPrimary ? ' primary' : '') +
                (isAlt ? ' alt' : '') +
                (isDanger ? ' danger' : '') +
                (isCancel ? ' cancel' : '')
            }
            onClick={onClick}
        >
            {children}
        </button>
    );
};

export default Button;
