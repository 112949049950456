import { useEffect } from 'react';
import './StopWatch.scss';
import Timer from './Timer';

function StopWatch({ isStarted, isPaused, time, setTime }) {
    useEffect(() => {
        let interval = null;

        if (isStarted === true && isPaused === false) {
            interval = setInterval(() => {
                setTime((time) => time + 10);
            }, 10);
        } else {
            clearInterval(interval);
        }
        return () => {
            clearInterval(interval);
        };
    }, [isStarted, isPaused, setTime]);

    return <div className="stop-watch">{isStarted && <Timer time={time} />}</div>;
}

export default StopWatch;
