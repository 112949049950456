import './Instructions.scss';

function Instructions() {
    const exampleVideoWebMSrc = `${process.env.PUBLIC_URL}/Searchle-WordExample.webm`;
    const exampleVideoMP4Src = `${process.env.PUBLIC_URL}/Searchle-WordExample.mp4`;

    return (
        <div id="SearchleGame-instructions">
            <h4>How to play:</h4>
            <ol>
                <li>Click Start to begin.</li>
                <li>
                    Words start with the gray letters and can go in any direction &mdash;{' '}
                    <i>even diagonally and backwards!</i>
                    <div className="sub">Note: The correct answers are all common words!</div>
                    <div>
                        <center>
                            <video playsInline autoPlay muted loop width="200px" height="95px">
                                <source src={exampleVideoMP4Src} type="video/mp4" />
                                <source src={exampleVideoWebMSrc} type="video/webm" />
                            </video>
                        </center>
                    </div>
                </li>
                <li>When you've found all of the words, you win!</li>
            </ol>
        </div>
    );
}

export default Instructions;
