export function getEastCoastTime(dateValue = null) {
    // Create a new Date object for the current time
    let now = new Date();
    if (dateValue !== null) {
        now = new Date(dateValue);
    }

    // Get the UTC time in milliseconds
    const utcTime = now.getTime() + now.getTimezoneOffset() * 60000;

    // Use the Intl.DateTimeFormat object to format the date in the US Eastern Time Zone
    const options = {
        timeZone: 'America/New_York',
        hour12: false,
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    };

    // Format the date and split into parts
    const formatter = new Intl.DateTimeFormat([], options);
    const parts = formatter.formatToParts(now);

    // Check if it is currently daylight saving time
    const isDaylightSavingTime = parts.some((part) => part.type === 'dayPeriod' && part.value === 'PM');

    const offset = isDaylightSavingTime ? -4 * 60 * 60 * 1000 : -5 * 60 * 60 * 1000;

    // Calculate the Eastern Time in milliseconds
    const epoch = utcTime + offset;

    // Filter out "literals" and add epoch
    const result = parts.reduce((acc, curr) => {
        if (curr.type !== 'literal') {
            acc[curr.type] = curr.value;
        }
        return acc;
    }, {});
    result.epoch = epoch;

    return result;
}

export function getDateShort(dateValue = null) {
    const dateValues = getEastCoastTime(dateValue);
    return dateValues.month + ' ' + dateValues.day;
}

export function getDateFull(dateValue = null) {
    const dateValues = getEastCoastTime(dateValue);
    return dateValues.month + ' ' + dateValues.day + ', ' + dateValues.year;
}

export function getDateFullWithWeekday(dateValue = null) {
    const dateValues = getEastCoastTime(dateValue);
    return dateValues.weekday + ', ' + dateValues.month + ' ' + dateValues.day + ', ' + dateValues.year;
}

export function getDateForStorage(dateValue = null) {
    const dateValues = getEastCoastTime(dateValue);
    return dateValues.month + dateValues.day + dateValues.year;
}

export function getDateForDb(dateValue = null) {
    const dateValues = getEastCoastTime(dateValue);
    return dateValues.month + '-' + dateValues.day + '-' + dateValues.year;
}

export function getDateEpoch() {
    const dateValues = getEastCoastTime();
    return dateValues.epoch;
}

export function parseTime(time) {
    const minutes = ('0' + Math.floor((time / 60000) % 60)).slice(-2);
    const seconds = ('0' + Math.floor((time / 1000) % 60)).slice(-2);
    const timePretty = minutes + ':' + seconds;
    return {
        mins: minutes,
        secs: seconds,
        pretty: timePretty,
    };
}
