// import { useSearchParams } from 'react-router-dom';
import { useEffect, React } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
// import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';
import BadRequest from './BadRequest';
import './Layout.scss';

const trackingId = process.env.REACT_APP_GA_TRACKING_ID;
let gaTestMode = false;
if (process.env.NODE_ENV === 'development') {
    gaTestMode = true;
}
// const apiUrl = process.env.REACT_APP_API_URL;

const Layout = () => {
    // const [searchParams] = useSearchParams();
    // const time = searchParams.get('time');
    // const date = searchParams.get('date');
    // let shareImageUrl = 'https://searchlegame.com/Searchle-share-bg.jpg';
    let errorFound = false;
    // if (time && date) {
    //     // Validate values
    //     const parsedTime = parseInt(time, 10);
    //     const parsedDate = parseInt(date, 10);
    //     if (isNaN(parsedTime) || isNaN(parsedDate)) {
    //         errorFound = true;
    //     } else {
    //         shareImageUrl = `${apiUrl}/generate-image?time=${encodeURIComponent(time)}&date=${encodeURIComponent(date)}`;
    //     }
    // }

    const location = useLocation();

    // Initialize GA
    useEffect(() => {
        if (trackingId) {
            ReactGA.initialize(trackingId, { testMode: gaTestMode });
        } else {
            console.warn('Google Analytics is not loaded.');
        }
    }, []);

    // Track page views
    useEffect(() => {
        if (trackingId) {
            ReactGA.send({ hitType: 'pageview', page: location.pathname });
        }
    }, [location]);
    return (
        <div className="Layout">
            {/* <Helmet>
                <meta property="og:image" content={shareImageUrl} />
            </Helmet> */}
            {errorFound && <BadRequest />}
            {!errorFound && <Outlet />}
        </div>
    );
};

export default Layout;
