import React from 'react';
import './Modal.scss';

const Modal = ({ show, onClose, allowClose = true, children }) => {
    if (!show) {
        return null;
    }

    return (
        <div className="modal-backdrop" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                {allowClose && (
                    <button className="close-button" onClick={onClose}>
                        X
                    </button>
                )}
                {children}
            </div>
        </div>
    );
};

export default Modal;
