import Button from '../Button';
import Modal from '../Modal';
import './ShareModal.scss';
import { getDateShort, getDateFull, getDateEpoch } from '../../sharedHelpers';
import { isMobile } from '../../helpers';

function ShareModal({ show, time }) {
    const prettyDate = getDateFull();
    const timeFormatted =
        ('0' + Math.floor((time / 60000) % 60)).slice(-2) + ':' + ('0' + Math.floor((time / 1000) % 60)).slice(-2);

    const title =
        time < 30000
            ? 'Literally insane!'
            : time < 60000
              ? 'Incredible!'
              : time < 120000
                ? 'Amazing!'
                : time < 180000
                  ? 'Good work!'
                  : 'You did it!';

    const handleShareClick = () => {
        const dateEpoch = getDateEpoch();
        const dateShort = getDateShort();

        // Construct the share message
        const linkUrl = `https://searchlegame.com/?time=${time}&date=${dateEpoch}`;
        const message = `${timeFormatted} ${linkUrl}`;

        if (navigator.share) {
            // See: https://developer.mozilla.org/en-US/docs/Web/API/Navigator/share
            let shareMsg = `${dateShort} - ${timeFormatted}`;
            let title = null;
            let text = null;
            if (isMobile()) {
                text = shareMsg;
            } else {
                title = undefined;
                text = shareMsg;
            }
            navigator
                .share({
                    title,
                    text,
                    url: linkUrl,
                })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing', error));
        } else {
            console.log('Share not supported on this browser, do it the old way.');

            // Create the SMS URL
            const smsURL = `sms:?&body=${encodeURIComponent(message)}`;

            // Create a temporary anchor element
            const tempLink = document.createElement('a');
            tempLink.href = smsURL;

            // Append the anchor to the body
            document.body.appendChild(tempLink);

            // Simulate a click on the anchor
            tempLink.click();

            // Remove the anchor from the document
            document.body.removeChild(tempLink);
        }
    };

    return (
        <div id="ShareModal">
            <Modal id="ShareModal" show={show} allowClose={false}>
                <h3>{title}</h3>
                <h2 className="time">{timeFormatted}</h2>
                <h4>{prettyDate}</h4>
                <p>Share your time with your friends!</p>
                <div className="button-group">
                    <Button onClick={handleShareClick} isPrimary={true}>
                        Share
                    </Button>
                </div>
            </Modal>
        </div>
    );
}

export default ShareModal;
