import usePageTitle from '../hooks/usePageTitle';
import './ErrorPage.scss';

function NotFound() {
    usePageTitle('Searchle - 404');

    const logoSrc = `${process.env.PUBLIC_URL}/Searchle-favicon.png`;

    return (
        <div className="ErrorPage">
            <main className="ErrorPage-main">
                <img src={logoSrc} className="Searchle-logo" alt="Searchle logo" width="300px" />
                <br />
                Not found (404)
            </main>
        </div>
    );
}

export default NotFound;
