import Node from './Node';
import './GameCanvas.scss';

function GameCanvas({
    game,
    nodeState,
    handleNodeMouseDown,
    handleNodeMouseHover,
    wordsFoundHighlights,
    selectedHighlight,
}) {
    return (
        <div id="SearchleGame-canvas">
            {game.grid &&
                game.grid.map((row, rowI) => (
                    <div className={'row row-' + rowI} key={'row row-' + rowI}>
                        {row.map((node, colI) => (
                            <div className={'col col-' + colI} key={'col col-' + colI}>
                                <Node
                                    className="letter"
                                    node={nodeState['x' + colI + 'y' + rowI]}
                                    handleMouseDown={handleNodeMouseDown}
                                    handleMouseHover={handleNodeMouseHover}
                                />
                            </div>
                        ))}
                    </div>
                ))}
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="40" fill="none">
                    {wordsFoundHighlights &&
                        wordsFoundHighlights.map((highlight, i) => (
                            <path stroke={highlight.color} d={highlight.d} key={'words-found-highlight-' + i}></path>
                        ))}
                    {selectedHighlight && <path stroke="#a3b1bf" d={selectedHighlight} key="selected-highlight"></path>}
                </g>
            </svg>
        </div>
    );
}

export default GameCanvas;
