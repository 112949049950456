import { parseTime } from '../../../sharedHelpers';
import './Timer.scss';

export default function Timer({ time }) {
    const parsedTime = parseTime(time);
    return (
        <div className="timer">
            <span className="digits">{parsedTime.mins}:</span>
            <span className="digits">{parsedTime.secs}</span>
        </div>
    );
}
