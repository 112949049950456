import { useRef, useState, useEffect } from 'react';

import StopWatch from './stopwatch/StopWatch';
import ShareModal from './ShareModal';
import { ALL_DIRECTIONS, SMALLEST_WORD_LENGTH } from './constants';
import { stall, doPathsCrossDiagonally } from '../../helpers';
import ControlButtons from './stopwatch/ControlButtons';
import './SearchleGame.scss';
import LoadingAnimation from './LoadingAnimation';
import GameCanvas from './GameCanvas';
import Instructions from './Instructions';
import { getDateForStorage } from '../../sharedHelpers';

const highlightPaddingTop = 41;
const highlightPaddingLeft = 44;
const nodeSpacingHoriz = 48;
const nodeSpacingVert = 60;
const highlightColorsUnique = ['#2f9c84', '#2f6d9c', '#9c2f91', '#2f349c', '#9c602f', '#9c972f', '#2f9c3e'];
const highlightColors = [...highlightColorsUnique].concat([...highlightColorsUnique]);
const dragMinDuration = 50;
let minLoadingDuration = 1000;
if (process.env.REACT_APP_SLOW_LOAD === 'true') {
    minLoadingDuration = 15000;
}

const apiUrl = process.env.REACT_APP_API_URL;

const storageDate = getDateForStorage();
const gameDataKey = 'searchleGameData';

function SearchleGame() {
    const mouseDownTime = useRef(null);
    const mouseX = useRef(null);
    const mouseY = useRef(null);
    const isTouch = useRef(false);
    const actualLoadTime = useRef(0);
    const [appReady, setAppReady] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [game, setGame] = useState({});
    const [isStarted, setIsStarted] = useState(null);
    const [isAboutToStart, setIsAboutToStart] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [time, setTime] = useState(0);
    const [savedGameTime, setSavedGameTime] = useState(0);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [nodeState, setNodeState] = useState({});
    const [selectedNodes, setSelectedNodes] = useState([]);
    const [selectedLetters, setSelectedLetters] = useState('');
    const [selectedHighlight, setSelectedHighlight] = useState('');
    const [wordsFound, setWordsFound] = useState([]);
    const [wordsFoundNodes, setWordsFoundNodes] = useState([]);
    const [wordsFoundHighlights, setWordsFoundHighlights] = useState([]);
    const [gameOver, setGameOver] = useState(false);
    const [message, setMessage] = useState('');

    // First run logic
    useEffect(() => {
        const startGame = () => {
            let gameOverValue = gameOver;
            let timeValue = null;

            // Load saved game data
            if (process.env.NODE_ENV !== 'development') {
                const gameData = JSON.parse(localStorage.getItem(gameDataKey));
                if (gameData && gameData.date === storageDate) {
                    if (gameData.time) {
                        timeValue = gameData.time;
                        setSavedGameTime(timeValue);
                        setTime(timeValue);

                        if (gameData.gameOver === true) {
                            gameOverValue = true;
                            setGameOver(gameOverValue);
                        }
                    }
                }
            }

            if (gameOverValue === true) {
                setMessage('Game over!');
            } else {
                // Start the game!
                setAppReady(true);
                setMessage('Begin!');
            }
        };

        const fetchGameDataAsync = async () => {
            try {
                let headers = {
                    // API Key auth (not secure, since this key is included in deployed code)
                    'x-api-key': process.env.REACT_APP_API_KEY,
                    // Allow text compression in response
                    'Accept-Encoding': '*',
                    // Request JSON response formatting
                    'Content-type': 'application/json; charset=UTF-8',
                    // Request CORS for our domain
                    // Don't think we need this, since the API isn't using it to set
                    // "access-control-allow-origin" : "https://searchlegame.com",
                };
                if (process.env.NODE_ENV === 'development') {
                    headers = {
                        'access-control-allow-origin': '*',
                        'Content-type': 'application/json; charset=UTF-8',
                    };
                }

                const startTime = Date.now();
                const response = await fetch(`${apiUrl}/daily`, { method: 'GET', headers });
                const responseJson = await response.json();
                actualLoadTime.current = Date.now() - startTime;

                if (isStarted) {
                    // Make sure the loading animation shows for a short time
                    const extraLoadTime = minLoadingDuration - actualLoadTime.current;
                    if (extraLoadTime > 0) {
                        await stall(extraLoadTime);
                    }
                }

                if (responseJson.error) {
                    console.error('API Error: ' + responseJson.error);
                } else {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('words', responseJson.words);
                    }
                    setGame(responseJson);

                    // Generate states for the nodes
                    let newNodeState = {};
                    responseJson.grid.map((row) => {
                        return row.map((node) => {
                            return (newNodeState['x' + node.x + 'y' + node.y] = {
                                ...node,
                                isSelected: false,
                                isRevealed: false,
                            });
                        });
                    });
                    setNodeState(newNodeState);
                    startGame();
                }
            } catch (error) {
                console.error('Error fetching game data.', error);
            }
        };
        fetchGameDataAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Keep track of selection with SVG highlighter
    useEffect(() => {
        if (selectedNodes.length > 0) {
            // Add letters to selection
            const letters = selectedNodes.map((n) => n.letter).join('');
            setSelectedLetters(letters);

            // Calculate SVG path
            let pathPoints = selectedNodes
                .map(
                    (n) =>
                        n.x * nodeSpacingHoriz +
                        highlightPaddingLeft +
                        ' ' +
                        (n.y * nodeSpacingVert + highlightPaddingTop),
                    // +'L',
                )
                .join(' ');
            if (selectedNodes.length === 1) {
                // For only one point, it needs to go to itself or it won't show up at all
                pathPoints = pathPoints + ' ' + pathPoints;
            }
            const highlight = 'M ' + pathPoints;
            setSelectedHighlight(highlight);
        } else {
            setSelectedLetters('');
            setSelectedHighlight('');
        }
    }, [selectedNodes]);

    // Keep track of found words with the SVG highlighter
    useEffect(() => {
        let highlights = [];
        wordsFoundNodes.map((nodes, i) => {
            const highlight = {
                color: highlightColors[i],
                d:
                    'M ' +
                    nodes
                        .map(
                            (n) =>
                                n.x * nodeSpacingHoriz +
                                highlightPaddingLeft +
                                ' ' +
                                (n.y * nodeSpacingVert + highlightPaddingTop),
                            //+ 'L',
                        )
                        .join(' '),
            };
            highlights.push(highlight);
            return nodes;
        });
        setWordsFoundHighlights(highlights);
    }, [wordsFoundNodes]);

    // Manage isLoading
    useEffect(() => {
        const newIsLoading = (isStarted && !appReady) || (!isStarted && isAboutToStart && appReady);
        setIsLoading(newIsLoading);
    }, [isStarted, appReady, isAboutToStart]);

    // Save the game data in localStorage
    useEffect(() => {
        // Only update once per second (actually at the halfway mark between seconds)
        if (Math.round(time / 1000) > Math.round(savedGameTime / 1000)) {
            setSavedGameTime(time);
            const gameData = {
                time: time,
                gameOver: false,
                date: storageDate,
            };
            localStorage.setItem(gameDataKey, JSON.stringify(gameData));
        }
    }, [time]);

    const handleStartResumeAsync = async () => {
        // On first click only
        if (isStarted === null && appReady) {
            // Make sure the loading animation shows for a short time
            const extraLoadTime = minLoadingDuration - actualLoadTime.current;
            if (extraLoadTime > 0) {
                setIsAboutToStart(true);
                await stall(extraLoadTime);
                setIsAboutToStart(false);
            }
        }
        setIsStarted(true);
        setIsPaused(false);
    };

    const handlePause = () => {
        // setIsStarted(false);
        setIsPaused(true);
        setMessage('');
    };

    const selectNode = (node) => {
        if (!node.isRevealed) {
            const nodeKey = 'x' + node.x + 'y' + node.y;

            // Get latest selected node (for backtracking and direction checking)
            const latestNode = getLatestNode(1);

            // Check if we're trying to backtrack
            // Get the previously selected node and see if we're selecting it again
            const prevNode = getLatestNode(2);
            if (prevNode && node.x === prevNode.x && node.y === prevNode.y) {
                // Remove node from selection
                removeNodesFromSelection([latestNode]);

                // Deselect the node
                const latestNodeKey = 'x' + latestNode.x + 'y' + latestNode.y;
                updateNode(latestNodeKey, { isSelected: false });
            } else if (!node.isSelected) {
                // Not backtracking! Try to select instead
                if (selectedNodes.length > 0) {
                    // Only allow nodes to be selected in valid directions
                    if (
                        !ALL_DIRECTIONS.some((dir) => {
                            const newX = latestNode.x + dir.x;
                            const newY = latestNode.y + dir.y;
                            return node.x === newX && node.y === newY;
                        })
                    ) {
                        return false;
                    }

                    // Don't allow crossing over itself
                    if (selectedNodes.length > 2) {
                        if (doPathsCrossDiagonally([...selectedNodes, node])) {
                            return false;
                        }
                    }
                }

                // Select the node
                updateNode(nodeKey, { isSelected: true });

                // Add node to selection
                addNodesToSelection([node]);
            }
        }
    };

    const updateNode = (key, changes) => {
        let newNodeState = { ...nodeState };
        newNodeState[key] = { ...newNodeState[key], ...changes };
        setNodeState(newNodeState);
    };

    const submitLetters = () => {
        if (selectedLetters.length > 0) {
            let wordFound = false;
            if (selectedLetters.length >= SMALLEST_WORD_LENGTH) {
                if (game.words.includes(selectedLetters)) {
                    if (
                        !selectedNodes.some((node, i) => {
                            // Returning true means the word is not using the correct nodes
                            if (node.next) {
                                // All we need to do is verify that the next node is one of the selected nodes.
                                // If we try to match the exact order, it will be confusing to the player in certain circumstances.
                                // For example, when there is a repeated letter in a word, it shouldn't matter what order you choose them in.
                                // Another example is for palindromes (like RACECAR).
                                if (!selectedNodes.some((n) => node.next.x === n.x && node.next.y === n.y)) {
                                    // console.error('next node is not part of selection', node, selectedNodes);
                                    return true;
                                }
                            }
                            return false;
                        })
                    ) {
                        // Word is correct!
                        wordFound = true;
                        const newWordsFound = [...wordsFound].concat([selectedLetters]).sort();
                        setWordsFound(newWordsFound);
                        setWordsFoundNodes(wordsFoundNodes.concat([selectedNodes]));
                        setMessage('Correct! Good Job!');

                        // Check if all words are found
                        if (JSON.stringify(game.words.sort()) === JSON.stringify(newWordsFound)) {
                            // End the game!
                            setMessage('Game over!');
                            setGameOver(true);

                            // Save the game data in localStorage
                            const gameData = {
                                time: time,
                                gameOver: true,
                                date: storageDate,
                            };
                            localStorage.setItem(gameDataKey, JSON.stringify(gameData));
                        }
                    } else {
                        setMessage('Very close!');
                    }
                } else {
                    setMessage('Sorry, not correct.');
                }
            } else {
                if (selectedLetters.length > 1) {
                    setMessage('Word too short.');
                } else {
                    setMessage('');
                }
            }
            resetNodes(wordFound);
        }
    };

    const addNodesToSelection = (nodes) => {
        const newSelection = [...selectedNodes].concat(nodes);
        setSelectedNodes(newSelection);
    };

    const removeNodesFromSelection = (nodes) => {
        const newSelection = [...selectedNodes].filter((n) => !nodes.some((node) => n.x === node.x && n.y === node.y));
        setSelectedNodes(newSelection);
    };

    const getLatestNode = (fromEnd = 1) => {
        let latestNode = null;
        if (selectedNodes.length >= fromEnd) {
            latestNode = selectedNodes[selectedNodes.length - fromEnd];
        }
        return latestNode;
    };

    const resetNodes = (reveal = false) => {
        // Reset nodes (and set revealed if successful)
        let newNodeState = { ...nodeState };
        // eslint-disable-next-line
        for (const [key, _] of Object.entries(newNodeState)) {
            if (newNodeState[key].isSelected) {
                newNodeState[key] = { ...newNodeState[key], isSelected: false };
                if (reveal) {
                    // Set nodes to revealed
                    newNodeState[key].isRevealed = true;
                }
            }
        }
        setNodeState(newNodeState);
        setSelectedNodes([]);
    };

    const handleMouseDown = (e) => {
        mouseDownTime.current = Date.now();
        mouseX.current = e.pageX;
        mouseY.current = e.pageY;
        setIsMouseDown(true);
        setIsDragging(false);
    };

    const handleMouseMove = (e) => {
        if (isMouseDown) {
            const diffX = Math.abs(e.pageX - mouseX.current);
            const diffY = Math.abs(e.pageY - mouseY.current);
            const duration = Date.now() - mouseDownTime.current;

            if ((diffX > 6 || diffY > 6) && duration >= dragMinDuration) {
                setIsDragging(true);
            }
        }
    };

    const handleMouseUp = () => {
        setIsMouseDown(false);
        if (isDragging) {
            setIsDragging(false);

            // Submit the letters
            submitLetters();
        }
    };

    const setIsTouch = () => {
        if (!isTouch.current) {
            isTouch.current = true;
        }
    };

    const handleTouchStart = (e) => {
        mouseDownTime.current = Date.now();
        mouseX.current = e.touches[0].pageX;
        mouseY.current = e.touches[0].pageY;

        setIsTouch();
        setIsMouseDown(true);

        const node = getNodeFromTouchTarget(e);
        if (node) {
            // Get latest node
            let latestNode = getLatestNode();
            const nodeIsLatest = latestNode && node.x === latestNode.x && node.y === latestNode.y;

            if (node.isSelected && nodeIsLatest) {
                // Submit the letters
                submitLetters();
            } else {
                // Select the node
                selectNode(node);
            }
        }
    };

    const handleTouchMove = (e) => {
        setIsTouch();
        if (isMouseDown) {
            let newIsDragging = isDragging;

            if (!newIsDragging) {
                const touch = e.touches[0];
                const diffX = Math.abs(touch.pageX - mouseX.current);
                const diffY = Math.abs(touch.pageY - mouseY.current);
                const duration = Date.now() - mouseDownTime.current;

                if ((diffX > 6 || diffY > 6) && duration >= dragMinDuration) {
                    newIsDragging = true;
                    setIsDragging(newIsDragging);
                }
            }

            if (newIsDragging) {
                const node = getNodeFromTouchTarget(e);
                if (node) {
                    // Select the node
                    selectNode(node);
                }
            }
        }
    };

    const getNodeFromTouchTarget = (e) => {
        const touch = e.touches[0];
        if (touch) {
            const target = document.elementFromPoint(touch.pageX, touch.pageY);
            if (target && target.dataset.nodeKey) {
                const key = target.dataset.nodeKey;
                const node = nodeState[key];
                return node;
            }
        }
        return null;
    };

    const handleTouchEnd = (e) => {
        setIsMouseDown(false);
        if (isDragging) {
            setIsDragging(false);

            // Submit the letters
            submitLetters();
        }
    };

    const handleNodeMouseDown = (node) => {
        // Get latest node
        let latestNode = getLatestNode();
        const nodeIsLatest = latestNode && node.x === latestNode.x && node.y === latestNode.y;

        // Mouse only. Touch is handled above.
        if (!isTouch.current) {
            // Only submit if clicking on the last letter in the selection
            if (node.isSelected && nodeIsLatest) {
                // Submit the letters
                submitLetters();
            } else {
                // Select the node
                selectNode(node);
            }
        }
    };

    const handleNodeMouseHover = (node) => {
        if (isDragging) {
            // Select the node
            selectNode(node);
        }
    };

    return (
        <div
            className="SearchleGame"
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        >
            <ShareModal show={gameOver} time={time} />
            {isLoading && <LoadingAnimation />}
            <div className="feedback-text">{isStarted && appReady && !isPaused ? selectedLetters || message : ''}</div>
            {isStarted && appReady && !isPaused && (
                <GameCanvas
                    game={game}
                    nodeState={nodeState}
                    handleNodeMouseDown={handleNodeMouseDown}
                    handleNodeMouseHover={handleNodeMouseHover}
                    wordsFoundHighlights={wordsFoundHighlights}
                    selectedHighlight={selectedHighlight}
                />
            )}
            {(!isStarted || isPaused) && !isLoading && <Instructions />}
            <StopWatch
                isStarted={isStarted && appReady}
                isPaused={isPaused || gameOver}
                time={time}
                setTime={setTime}
            />
            {!gameOver && !isLoading && (
                <ControlButtons
                    isStarted={isStarted}
                    appReady={appReady}
                    isPaused={isPaused}
                    handleStartAsync={handleStartResumeAsync}
                    handlePause={handlePause}
                    handleResumeAsync={handleStartResumeAsync}
                />
            )}
            {(!isStarted || isPaused) && !isLoading && (
                <div className="SearchleGame-footer">
                    &copy; Searchle
                    <br />
                    <a href="/privacy">Privacy Policy</a> | <a href="/terms">Terms of Service</a>
                </div>
            )}
        </div>
    );
}

export default SearchleGame;
